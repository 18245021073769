import React, { Component } from "react";
import SideBar from "../sidebar";
import Footer from "../footer";
import moment from "moment";
import Input from "../../helpers/input";
import Button from "../../helpers/button";
import Select from "../../helpers/select";
import {
  GET_SERVICES,
  ADD_PAYMENT,
  ADD_PAYMENT_FORM,
  yearsList,
  monthsList
} from "../../helpers/constants";
import axiosFetch from "../../helpers/axios";
import AlertBanner from "../../helpers/alert-banner"
import PaymentsTable from "../payments-table"

import "./add-payment.scss";

export default class AddPayment extends Component {
  state = {
    serviceId: 0,
    paymentSumm: "",
    price: "",
    servicesArray: [],
    yearsArray: yearsList(),
    monthsArray: monthsList(),
    month: "",
    year: "",
    reload: false,
    error: "",

  };



  componentDidMount() {
    this.getProducts();
    const monthName = moment().format('MMMM')
    const month = moment().month(monthName).format('M');
    const year = moment().format('YYYY')
    this.setState({
      month, year
    })

   
  }

  componentDidUpdate(prevProps) {
    const { activeForm, onSubmitForm } = this.props

    if (activeForm === ADD_PAYMENT_FORM && prevProps.onSubmitForm !== onSubmitForm) {
      this.addPayment()
    }
  }


  getProducts = () => {
    axiosFetch(GET_SERVICES).then((servicesArray) => {
      this.setState({ servicesArray });
    });
  };

  changeFieldsState = (field, inputValue) => {
    this.setState({ [field]: inputValue });
  };

  addPayment = () => {
    const { year, month, serviceId, paymentSumm } = this.state;

    const formData = new FormData();
    formData.append("usluga", serviceId);
    formData.append("month", month);
    formData.append("cash", paymentSumm);
    formData.append("year", year);

    axiosFetch(ADD_PAYMENT, formData).then((response) => {

      if (response === "ok") {
        this.setState({ reload: true })
      } else {
        this.setState({ error: "Такая оплата уже существует" })
      }
    });
  };

  render() {
    const { pageTitle, onShowConfirmModal } = this.props;
    const {
      serviceId,
      paymentSumm,
      servicesArray,
      yearsArray,
      monthsArray,
      month,
      year,
      reload,
      error,
    } = this.state;

    const disabled = !paymentSumm || !serviceId || !month || !year;

    return (
      <div className="wrapper ">

        {reload && (
          <AlertBanner
            type={"success"}
            fontAwesomeType={"fa-check"}
            title={"Добавлено"}
            timer={2}
          />
        )}

        <SideBar activeMenuItem={pageTitle} />
        <div className="main-panel">
          <div className="content">
            <div className="container-fluid">

              <div className="col-md-12 date-container">
                <div className="card card-stats row">
                  <div className="col-md-3">
                    <Select
                      className={"form-control"}
                      items={servicesArray}
                      defaultValue={"Выберите услугу"}
                      colMd={""}
                      selectedItem={serviceId}
                      onChange={(e) =>
                        this.changeFieldsState("serviceId", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      className={"form-control"}
                      type={"number"}
                      id={"paymentSumm"}
                      value={paymentSumm}
                      placeholder={"Сумма оплаты"}
                      onChange={(e) =>
                        this.changeFieldsState("paymentSumm", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      className={"form-control"}
                      items={monthsArray}
                      defaultValue={"Месяц"}
                      colMd={""}
                      selectedItem={month}
                      onChange={(e) =>
                        this.changeFieldsState("month", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      className={"form-control"}
                      items={yearsArray}
                      defaultValue={"Год"}
                      colMd={""}
                      selectedItem={year}
                      onChange={(e) =>
                        this.changeFieldsState("year", e.target.value)
                      }
                    />
                  </div>
                  <Button
                    type={"button"}
                    text={"Добавить"}
                    disabled={disabled}
                    className={"btn btn-success waves-effect waves-light m-l-5"}
                    onClick={() => onShowConfirmModal(ADD_PAYMENT_FORM)}
                  />
                  <h3 className="add-payment-error">{error}</h3>
                </div>
              </div>


              <div className="content ">
                <div className="container-fluid">
                  <div className="col-md-12 date-container print-button-container ">
                    <PaymentsTable {...{servicesArray}}/>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
