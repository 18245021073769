import React, { Component } from "react";
import moment from "moment";
import Spinner from "../../helpers/spinner";
import Select from "../../helpers/select";
import { GET_PAYMENTS_LIST } from "../../helpers/constants";
import axiosFetch from "../../helpers/axios";
import { yearsList, monthsList, amountFormatter, regCash } from "../../helpers/constants"
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

import classnames from 'classnames'

import "./payments-table.scss";

export default class PaymentsTable extends Component {
  state = {
    paymentsData: [],
    yearsArray: yearsList(),
    year: "",
    isFetching: false,
    totalMonthSumm: [],
    activeLine: "",
    monthsArray: monthsList(),
    dataCharts: [],
    serviceId: 0,
  };

  componentDidMount() {
    const year = moment().format('YYYY')
    this.setState({
      year,
      isFetching: true
    }, () => {
      this.getPaymentsList(year);
    })
  }

  changeFieldsState = (inputValue) => {
    this.setState({ year: inputValue, isFetching: true },
      () => {
        this.getPaymentsList(inputValue);
      });
  };

  serRestMontsData = (month) => {
    const { paymentsData } = this.state
    const arr = []

    paymentsData.forEach((item) => {
      arr.push({
        [`amount${item.serviceId}`]: item.payments.find(pay => Number(pay.month) === month)?.cash || 0,
      })
    })
    return arr
  }

  setChartInfo = (filteredService = 0) => {

    const sId = Number(filteredService)

    const { monthsArray, paymentsData } = this.state
    let dataCharts = []

    monthsArray.forEach(({ id, name }) => {

      dataCharts.push({
        name: id
      })
    })

    if (sId > 0) {

    }

    paymentsData.forEach((item) => {
      const { payments, serviceId } = item
      if (Number(filteredService) > 0 && Number(serviceId) === Number(filteredService)) {

        monthsArray.forEach((month, index) => {
          const QQQ = payments.find((pay) => Number(pay.month) === month.id)?.cash || 0
          payments.forEach(() => {
            dataCharts[index] = { ...dataCharts[index], [`amount${serviceId}`]: Number(regCash(QQQ) || 0) }
          })
        })
      } else if (Number(filteredService) === 0 && Number(serviceId) !== 26) {
        monthsArray.forEach((month, index) => {
          const QQQ = payments.find((pay) => Number(pay.month) === month.id)?.cash || 0
          payments.forEach(() => {
            dataCharts[index] = { ...dataCharts[index], [`amount${serviceId}`]: Number(regCash(QQQ) || 0) }
          })
        })
      }
    })

    this.setState({ dataCharts })

  }

  getPaymentsList = (year) => {

    const formData = new FormData();
    formData.append("year", year);

    axiosFetch(GET_PAYMENTS_LIST, formData).then((paymentsData) => {
      this.setState({
        paymentsData,
        isFetching: false
      }, () => {
        this.setChartInfo()
      });
    });
  };

  renderThead = () => {
    const theadItems = [""];
    monthsList().forEach(month => theadItems.push(month.name))
    theadItems.push("Итого")

    const theadContent = theadItems.map((item, index) => {
      return (
        <th key={index}>{item}</th>
      )
    })

    return theadContent
  }

  renderRestTr = (payments) => {

    const arr = [];
    let summ = 0;

    for (let i = 1; i <= 12; i++) {
      const cash = payments.find(pay => Number(pay.month) === i)?.cash || ""

      if (cash.length > 0) {
        const newCash = cash.length > 0 ? regCash(cash) : ""

        arr.push(newCash)
        summ = Number(newCash) + summ
      } else {
        arr.push("")
      }
    }

    const filteredArr = arr.filter(item => Number(item) > 0)



    const max = Math.max(...arr)
    const min = Math.min(...filteredArr)

    const countMax = arr.filter(item => Number(item) === max).length
    const countMin = filteredArr.filter(item => Number(item) === min).length

    arr.push(Math.round(summ * 100) / 100)

    let ma = 0, mi = 0

    const content = arr.map((td, index) => {
      max === Number(td) && ma++
      min === Number(td) && mi++


      // console.log("countMax / countMax", countMax, max, countMin, min)
      return (
        <td
          key={index}
          className={classnames({
            'max-payment': max === Number(td) && ma === countMax,
            'min-payment': min === Number(td) && mi === countMin,
          })} >
          {amountFormatter(td)}
        </td>
      )
    })

    return content
  }



  selectedTableLine = (line) => {
    this.setState({
      activeLine: line
    })
  }

  renderTbody = (paymentsData) => {
    const { activeLine } = this.state
    const tbody = paymentsData.map((dataLine, index) => {
      const { serviceName, payments } = dataLine
      const monthName = moment().format('MMMM')
      const month = moment().month(monthName).format('M');
      const havePayment = payments.find(pay => pay.month === month)

      return (
        <tr
          key={index}
          className={classnames({
            "selected-table-line": activeLine === serviceName,
            "have-payment": havePayment
          })}
          onClick={() => this.selectedTableLine(serviceName)}
        >
          <td>{serviceName}</td>
          {payments && this.renderRestTr(payments)}
        </tr>
      )
    })
    return tbody
  }

  renderTfooter = (paymentsData) => {
    const arr = ["Сумма по месяцам"];


    let totalSumm = 0;

    for (let i = 1; i <= 12; i++) {
      let summ = 0;
      paymentsData.forEach(({ payments }) => {

        const cash = (payments && payments.find(pay => Number(pay.month) === i)?.cash) || ""
        const newCash = regCash(cash)
        summ += Number(newCash)
      })
      arr.push(Math.round(summ * 100) / 100)
      totalSumm = totalSumm + summ

    }

    const max = Math.max(...arr.slice(1))
    const min = Math.min(...arr.slice(1))

    const countMax = arr.filter(item => Number(item) === max).length
    const countMin = arr.filter(item => Number(item) === min).length

    arr.push(Math.round(totalSumm * 100) / 100)

    let ma = 0, mi = 0

    const content = arr.map((td, index) => {
      max === Number(td) && ma++
      min === Number(td) && mi++
      return (
        <td
          key={index}
          className={classnames({
            'max-payment': max === Number(td) && ma === countMax,
            'min-payment': min === Number(td) && mi === countMin,
          })}
        >{td > 0 ? amountFormatter(td) : td}</td>
      )
    })

    return content
  }

  renderPaymentsTable = () => {
    const { paymentsData } = this.state

    return (
      <table className="payments-lines-container">
        <thead>
          <tr>
            {this.renderThead()}
          </tr>
        </thead>
        <tbody>
          {this.renderTbody(paymentsData)}
        </tbody>
        <tfoot>
          <tr>
            {this.renderTfooter(paymentsData)}
          </tr>
        </tfoot>
      </table>

    )
  }

  renderLines = () => {
    const { paymentsData } = this.state
    // console.log("paymentsData", paymentsData)
    const lines = (paymentsData || []).map(({ serviceId, color }, index) => {
      // console.log("color", color)
      return (
        <Line key={index} type="monotone" dataKey={`amount${serviceId}`} stroke={color} />
      )
    })
    return lines

  }

  changeChartData = (serviceId) => {
    this.setState({ serviceId }, () => {
      this.setChartInfo(serviceId)
    })
  }


  render() {
    const { servicesArray } = this.props
    const {
      yearsArray,
      year,
      isFetching,
      dataCharts,
      serviceId
    } = this.state;

    const content = isFetching ? <Spinner /> : this.renderPaymentsTable()

    return (
      <div className="payments-container">
        <div className="col-md-3">

          <Select
            className={"form-control"}
            items={yearsArray}
            colMd={""}
            selectedItem={year}
            onChange={(e) =>
              this.changeFieldsState(e.target.value)
            }
          />
          <Select
            items={servicesArray}
            defaultValue={"Все услуги"}
            colMd={""}
            selectedItem={serviceId}
            onChange={(e) => this.changeChartData(e.target.value)}
          />


        </div>

        <div className="content ">
          <div className="container-fluid">
            <div className="col-md-12 date-container ">
              <LineChart width={400} height={500} data={dataCharts}>
                {this.renderLines()}
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" />
                <YAxis />
              </LineChart>

            </div>
          </div>
        </div>

        <div className="payments-table-info">
          {content}
        </div>
      </div>


    );
  }
}
