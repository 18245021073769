import React, { Component } from "react";
import SideBar from "../sidebar";
import Footer from "../footer";
import axiosFetch from "../../helpers/axios";
import StatisticTable from "./statistic-table";
import ReactToPrint from "react-to-print";
import OrderTable from "../add-payment/order-table";
import Button from "../../helpers/button";
import Pagination from '../../helpers/pagination';
import { GET_PAYMENTS, GET_COUNT_ALL_ORDERS, ITEMS_ON_THE_PAGE  } from "../../helpers/constants";

import "./statistic.scss";

export default class Statistic extends Component {
  state = {
    tablesHeaders: [
      { th: "#" },
      { th: "Наименование услуги" },
      { th: "Сумма оплаты" },
      { th: "месяц/год" },
      // { th: "Детали оплаты" },
    ],
    ordersArray: [],
    showDetailsModal: false,
    orderItems: [],
    currentPage: 0,
    countAllOrders: 0,
  };

  componentWillMount() {
    this.getPayments();
    this.getCountAllOrders();
  }

  getCountAllOrders = () => {
    const countAllOrders = axiosFetch(GET_COUNT_ALL_ORDERS);
    this.promiseResolved(countAllOrders, 'countAllOrders');
 }

 promiseResolved = (arr, state) => {
  Promise.resolve(arr).then((arrList) => {
    this.setState({
      [state]: arrList,
      showSpinner: false,
    });
  });
};

  changeFieldsState = (field, inputValue) => {
    this.setState({ [field]: inputValue });
  };

  getPayments = () => {
    const { currentPage } = this.state;
        const fd = new FormData();

        fd.append('currentPage', currentPage)
        fd.append('itemsOnPage', ITEMS_ON_THE_PAGE)
        axiosFetch(GET_PAYMENTS, fd).then((ordersArray)=>{
          this.promiseResolved(ordersArray, 'ordersArray');

        });
       
        
    // axiosFetch(GET_PAYMENTS).then((ordersArray) => {
    //   this.setState({ ordersArray });
    // });
  };

  showDetailsModal = (orderItems) => {

    const tablesHeadersToPrint = [
      { th: "#" },
      { th: "Наименование заказа" },
      { th: "Количество" },
      { th: "Цена (ед.)" },
      { th: "Сумма заказа (лей)" },
      { th: "Действия" },
    ]


    return (
      <div className={"details-modal"}>
        <div className={"details-modals-content"}>
          <div
            className={"close-icon"}
            onClick={() => this.setState({ showDetailsModal: false })}
          >
            X
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Цена (лей)</th>
                <th>Кол-во (шт.)</th>
                <th>Итого (лей)</th>
              </tr>
            </thead>
            <tbody>
              {this.renderOrderDetails(orderItems)}
            </tbody>
          </table>

          <ReactToPrint
            trigger={() => {
              return (
                <Button
                  type={"button"}
                  text={"Распечатать"}
                  className={
                    "btn btn-success waves-effect waves-light m-l-5"
                  }
                />
              );
            }}
            content={() => this.componentRef}
          />
          <OrderTable
            tablesHeaders={tablesHeadersToPrint}
            tableData={orderItems}
            ref={(el) => (this.componentRef = el)}
          />
        </div>
      </div>
    );
  };

  renderOrderDetails = (orderItems) => {
    const orderDetails = orderItems.map((oneLine) => {
      const { productName, price, count, id } = oneLine;
      return (
        <tr key={id}>
          <td>{productName} </td>
          <td> {price} </td>
          <td> {count} </td>
          <td> {Math.floor((count * price) * 100) / 100}</td>
        </tr>
      );
    });

    return orderDetails;
  };

  showOrderDetails = (orderItems) => {
    this.setState({ showDetailsModal: true, orderItems });
  };

  paginationClick = page => {

    this.setState({ currentPage: page }, () => {
        this.getPayments()
    })
}



  render() {
    const { pageTitle } = this.props;
    const {
      tablesHeaders,
      ordersArray,
      showDetailsModal,
      orderItems,
      currentPage,
      countAllOrders
    } = this.state;

    return (
      <div className="wrapper ">
        {showDetailsModal && this.showDetailsModal(orderItems)}
        <SideBar activeMenuItem={pageTitle} />
        <div className="main-panel">
          <div className="content">
            <div className="container-fluid">
              <StatisticTable
                tablesHeaders={tablesHeaders}
                tableData={ordersArray}
                showOrderDetails={this.showOrderDetails}
                getPayments={this.getPayments}
              />
            </div>

            {ordersArray && <Pagination
                onClick={this.paginationClick}
                total={countAllOrders}
                pageIndex={currentPage}
                pageSize={10}
            />}


          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
