import React, { Component } from 'react';

import './select.scss';

export default class Select extends Component {  

    renderSelectItems = () => {
        const { items, selectedItem } = this.props;
        const menu = items.map((menuItem)=> {
            const { id, value, name } = menuItem;

            return(
                <option key={id} value={id} defaultValue={selectedItem === id && "selected"}>
                    {value || name}
                </option>
            );
        })
        return menu;
    }

    render(){
        const { 
            required = true, 
            colMd = 'col-md-12 mb-3', 
            name = 'input',  
            defaultValue,
            disabled = false,
            onChange,
            className,
            selectedItem
        } = this.props;

        return(
            <div className={colMd}>
                <select 
                    className={className} 
                    onChange={onChange} 
                    name={name} 
                    required={required}
                    disabled={disabled}
                    value={selectedItem}
                >
                    <option value={0} className="option">{defaultValue}</option>
                    {this.renderSelectItems()}
                </select>
            </div>
        );
    };
}