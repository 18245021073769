import React, { Component } from 'react'
import SideBar from '../sidebar'
import Footer from '../footer'
import LibraryTable from './library-table'
import Input from '../../helpers/input'
import Button from '../../helpers/button'
import { ADD_SERVICE, GET_SERVICES, REMOVE_SERVICE, ADD_SERVICE_FORM, DELETE_SERVICE_FORM } from '../../helpers/constants'
import axiosFetch from '../../helpers/axios';

import './library.scss'

export default class Dashboard extends Component {
    state = {
        serviceName: "",
        tablesHeaders: [
            { th: 'Наименование услуги' },
            { th: 'Действия' },
        ],
        tableData: [],
        servicesArray: [],
        deleteItemId: 0,
    }

    componentDidMount() {
        this.getProducts()
    }

    componentDidUpdate(prevProps){
        const {activeForm, onSubmitForm} = this.props
 
        if( activeForm === ADD_SERVICE_FORM && prevProps.onSubmitForm !== onSubmitForm){
            this.addService()
        }

        if( activeForm === DELETE_SERVICE_FORM && prevProps.onSubmitForm !== onSubmitForm){
            this.onDeleteItem()
        }
      }

    getProducts = () => {
        axiosFetch(GET_SERVICES).then((servicesArray) => {
            this.setState({ servicesArray, serviceName: "" });
        });
    }

    changeFieldsState = (field, inputValue) => {
        this.setState({ [field]: inputValue });
    }

    addService = () => {
        const { onSubmitFormStatus } = this.props
        const { serviceName } = this.state
        const fd = new FormData();
        fd.append("serviceName", serviceName);

        axiosFetch(ADD_SERVICE, fd).then(() => {
            this.getProducts()
            onSubmitFormStatus()
        });
    }

    setDeleteItemId = id => {
        this.setState({
            deleteItemId: id
        })
    }

    onDeleteItem = () => {
        const { deleteItemId } = this.state
        const fd = new FormData();
        fd.append("id", deleteItemId);

        axiosFetch(REMOVE_SERVICE, fd).then(() => {
            this.getProducts()
        });
    }

    render() {
        const { pageTitle, onShowConfirmModal } = this.props
        const { tablesHeaders, servicesArray, serviceName } = this.state
        return (
            <div className="wrapper ">
                <SideBar activeMenuItem={pageTitle} />
                <div className="main-panel">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="col-md-12 date-container">
                                <div className="card card-stats row">
                                    <div className="col-md-4">
                                        <label htmlFor="serviceName"></label>
                                        <Input className={"form-control"}
                                            type={"text"}
                                            id={"serviceName"}
                                            placeholder={"Наименование"}
                                            value={serviceName}
                                            onChange={(e) => this.changeFieldsState("serviceName", e.target.value)}
                                        />
                                    </div>

                                    <Button type={'button'}
                                        text={'Добавить услугу в библиотеку'}
                                        className={'btn btn-success '}
                                        disabled={!serviceName}
                                        onClick={() => onShowConfirmModal(ADD_SERVICE_FORM)}
                                        />
                                </div>
                            </div>

                            <LibraryTable tablesHeaders={tablesHeaders}
                                tableData={servicesArray}
                                // onDeleteItem={this.onDeleteItem}
                                setDeleteItemId={this.setDeleteItemId}
                                onDeleteItem={() => onShowConfirmModal(DELETE_SERVICE_FORM)}
                                reloadTable={this.getProducts} />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}