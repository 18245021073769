import React from 'react';
import ReactDOM from 'react-dom';
import Store from "./store";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import App from './components/app';

const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={Store}>
        <Router history={history} basename='/'>
                <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);