import {
    SHOW_CONFIRM_MODAL,
    SHOW_MENU,
    SUBMIT_FORM_STATUS
} from "../actions";

const initialState = {
    showModal: false,
    showMenu: false,
    showConfirmModal: false,
    activeForm: "",
    onSubmitForm: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case SHOW_CONFIRM_MODAL:
            return Object.assign({}, {
                ...state,
                showConfirmModal: !state.showConfirmModal,
                activeForm: action.payload
            });

        case SUBMIT_FORM_STATUS:
            return Object.assign({}, {
                ...state,
                onSubmitForm: !state.onSubmitForm
            });

        case SHOW_MENU:
            return Object.assign({}, {
                ...state,
                showMenu: !state.showMenu
            });

        default:
            return state;
    }
}
