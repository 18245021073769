import React, { Component } from "react";
import SideBar from "../sidebar";
import Footer from "../footer";
import moment from "moment";
import Input from "../../helpers/input";
import Button from "../../helpers/button";
import Select from "../../helpers/select";
import {
  GET_SERVICES,
  SHOW_PAYMENT,
  yearsList,
  monthsList,
  SEARCH_PAYMENT_FORM,
  EDIT_PAYMENT_FORM,
  EDIT_PAYMENT,
  regCash,
  scrollToElement
} from "../../helpers/constants";
import axiosFetch from "../../helpers/axios";
import AlertBanner from "../../helpers/alert-banner"
import PaymentsTable from "../payments-table"

import "./edit-payment.scss";

export default class EditPayment extends Component {
  state = {
    serviceId: 0,
    price: "",
    servicesArray: [],
    yearsArray: yearsList(),
    monthsArray: monthsList(),
    month: "",
    year: "",
    reload: false,
    error: "",
    editError: "",
    searchPaymentData: {},
    paymentSumm: 0,
  };



  componentDidMount() {
    this.getProducts();
    const monthName = moment().format('MMMM')
    const month = moment().month(monthName).format('M');
    const year = moment().format('YYYY')
    this.setState({
      month, year
    })
  }

  componentDidUpdate(prevProps) {
    const { activeForm, onSubmitForm } = this.props

    if (activeForm === SEARCH_PAYMENT_FORM && prevProps.onSubmitForm !== onSubmitForm) {
      this.searchPayment()
      scrollToElement()
    }
    if (activeForm === EDIT_PAYMENT_FORM && prevProps.onSubmitForm !== onSubmitForm) {
      this.editPayment()
    }


  }

  getProducts = () => {
    axiosFetch(GET_SERVICES).then((servicesArray) => {
      this.setState({ servicesArray });
    });
  };

  changeFieldsState = (field, inputValue) => {
    this.setState({ [field]: inputValue });
  };

  searchPayment = () => {
    const { year, month, serviceId, } = this.state;

    const formData = new FormData();
    formData.append("usluga", serviceId);
    formData.append("month", month);
    formData.append("year", year);

    axiosFetch(SHOW_PAYMENT, formData).then((searchPaymentData) => {

      if (searchPaymentData[0]) {
        this.setState({ searchPaymentDataId: searchPaymentData[0].id, paymentSumm: Number(regCash(searchPaymentData[0].cash)) })
      } else {
        this.setState({ error: "Такой оплаты не было найдено" })
      }
    });
  };

  editPayment = () => {
    const { searchPaymentDataId, paymentSumm } = this.state;

    const formData = new FormData();
    formData.append("cash", paymentSumm);
    formData.append("id", searchPaymentDataId);

    axiosFetch(EDIT_PAYMENT, formData).then((result) => {

      if (result === "ok") {
        this.setState({ searchPaymentDataId: 0, paymentSumm: 0, reload: true })
      } else {
        this.setState({ editError: "Что-то пошло не так" })
      }
    }).catch((err) => {
      console.log("err", err)
      this.setState({ editError: "Что-то пошло не так" })
    });
  };

  onSearchPayment = () => {
    const { onShowConfirmModal } = this.props;
    this.setState({ searchPaymentDataId: 0, paymentSumm: 0 }, () => {
      onShowConfirmModal(SEARCH_PAYMENT_FORM)
    })

  }

  render() {
    const { pageTitle, onShowConfirmModal } = this.props;
    const {
      serviceId,
      servicesArray,
      yearsArray,
      monthsArray,
      month,
      year,
      reload,
      error,
      editError,
      searchPaymentDataId,
      paymentSumm
    } = this.state;

    const disabled = !serviceId || !month || !year;

    const serviceName = servicesArray.filter(item => item.id === serviceId)

    return (
      <div className="wrapper ">

        {reload && (
          <AlertBanner
            type={"success"}
            fontAwesomeType={"fa-check"}
            title={"Изменено"}
            timer={2}
          />
        )}

        <SideBar activeMenuItem={pageTitle} />
        <div className="main-panel">
          <div className="content">
            <div className="container-fluid">

              <div className="col-md-12 date-container">

                {searchPaymentDataId > 0 && <div className="card card-stats row find-container">
                  <div>
                    <h3 className="edit-payment-error">{editError}</h3>
                    <h4 className="edit-service-name">{serviceName[0].name} / {month} / {year}</h4>
                    <Input
                      className={"form-control"}
                      type={"number"}
                      value={paymentSumm}
                      placeholder={"Сумма оплаты"}
                      onChange={(e) =>
                        this.changeFieldsState("paymentSumm", e.target.value)
                      }
                    />
                  </div>
                  <Button
                    type={"button"}
                    text={"Редактировать"}
                    disabled={disabled}
                    className={"btn btn-success waves-effect waves-light m-l-5"}
                    onClick={() => onShowConfirmModal(EDIT_PAYMENT_FORM)}
                  />
                </div>}
                <div className="card card-stats row">
                  <div className="col-md-3">
                    <Select
                      className={"form-control"}
                      items={servicesArray}
                      defaultValue={"Выберите услугу"}
                      colMd={""}
                      selectedItem={serviceId}
                      onChange={(e) =>
                        this.changeFieldsState("serviceId", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      className={"form-control"}
                      items={monthsArray}
                      defaultValue={"Месяц"}
                      colMd={""}
                      selectedItem={month}
                      onChange={(e) =>
                        this.changeFieldsState("month", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      className={"form-control"}
                      items={yearsArray}
                      defaultValue={"Год"}
                      colMd={""}
                      selectedItem={year}
                      onChange={(e) =>
                        this.changeFieldsState("year", e.target.value)
                      }
                    />
                  </div>
                  <Button
                    type={"button"}
                    text={"Искать"}
                    disabled={disabled}
                    className={"btn btn-success waves-effect waves-light m-l-5"}
                    onClick={() => this.onSearchPayment()}
                  />
                  {error && <h3 className="edit-payment-error">{error}</h3>}
                </div>
              </div>

              <div className="content ">
                <div className="container-fluid">
                  <div className="col-md-12 date-container print-button-container ">
                    <PaymentsTable {...{ servicesArray }} />
                  </div>
                </div>
              </div>


            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
