import React, { Component } from "react";
import Spinner from "../../../helpers/spinner";

import "./statistic-table.scss";

export default class StatisticTable extends Component {
  state = {
    tableData: [],
    tablesHeaders: [],
    showSpinner: true,
    totalSumm: 0,
  };

  componentDidMount() {
    const { tablesHeaders, tableData } = this.props;
    this.setState({ tablesHeaders, tableData, showSpinner: false });
  }

  componentDidUpdate(prevProps) {
    const { tablesHeaders, tableData } = this.props;
    if (tableData !== prevProps.tableData) {
      this.setState({ tablesHeaders, tableData, showSpinner: true });
      setTimeout(() => {
        this.setState({ showSpinner: false });
      }, 500);
    }
  }

  renderTableHeaders = (items) => {
    const tableHead = items.map((item, i) => {
      const { th } = item;
      return <th key={i}> {th} </th>;
    });
    return tableHead;
  };

  renderNoData = () => {
    return <div className="no-data">В заказ пока ничего не было добавлено</div>;
  };

  renderTableData = (items) => {

    const tableHead = items.map((item, i) => {
      const { cash, month, year, serviceName, currentItem } = item;

      return (
        <tr key={i}>
          <td className="first-row"> {currentItem} </td>
          <td>{serviceName}</td>
          <td className="price"> {cash} </td>
          <td> {month}/{year} </td>
        </tr>
      );
    });

    return tableHead;
  };

  renderTable = (tablesHeaders, tableData) => {
    return (
      <div className={"print-container"}>
        <div className={"company-container"}></div>
        <div className="clear" />

        <table className="table table-hover">
          <thead>
            <tr>{this.renderTableHeaders(tablesHeaders)}</tr>
          </thead>
          <tbody>{this.renderTableData(tableData)}</tbody>
        </table>
      </div>
    );
  };

  render() {
    const { tablesHeaders, tableData, showSpinner } = this.state;

    const content =
      tableData.length === 0
        ? this.renderNoData()
        : this.renderTable(tablesHeaders, tableData);
    return (
      <div className="table-responsive">
        {showSpinner && <Spinner />}
        {content}
      </div>
    );
  }
}
