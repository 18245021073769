import React, { Component } from 'react';
import close from "../../images/close.png"
import Input from "../../helpers/input";
import classnames from 'classnames';
import Spinner from "../../helpers/spinner";
import { GLOBAL_PASSWORD } from "../../helpers/constants";

import './confirm-modal.scss'

export default class ConfirmModal extends Component {
    state = {
        password: "",
        error: "",
        showType: false,
        isFetching: false,
    }

    setPasswordValue = value => {
        const { password } = this.state
        if (password.length <= 3) {
            this.setState({
                password: `${password}${value}`,
                error: ""
            })
        }

    }

    checPasswordAndSubmit = () => {
        const { onSubmitFormStatus, onShowConfirmModal } = this.props
        const { password } = this.state
        this.setState({ isFetching: true, error: "" })
        setTimeout(() => {
            if (Number(password) !== GLOBAL_PASSWORD) {
                this.setState({ error: "Неверный пароль" })
            } else {

                onSubmitFormStatus()
                onShowConfirmModal()
            }
            this.setState({ isFetching: false }, () => {
                
            })
        }, 1000);
    }

    render() {
        const { onShowConfirmModal } = this.props
        const { password, showType, isFetching, error } = this.state

        const passwordWithoutLastChr = password.substring(0, password.length - 1)
        const type = showType ? "text" : "password"
        return (
            <div className="confirm-modal">


                <div className="confirm-modal-overlay"></div>
                <div className="confirm-modal-content">
                    <h3>Введите пароль</h3>
                    <img
                        src={close}
                        alt="Close-menu"
                        onClick={() => onShowConfirmModal()}
                    />


                    <div className="col-md-12">
                        <div className="show-type-container">

                            <Input
                                className={"form-control password-input"}
                                {...{ type }}
                                id={"text"}
                                value={password}
                                maxLength={4}
                                readonly="readonly"
                                onChange={(e) =>
                                    this.setState({ password: e.target.value })
                                }
                            />
                            <div
                                className={classnames({
                                    'show-type': true,
                                    'show': showType,
                                })}
                                onClick={() => this.setState({ showType: !showType })}></div>

                        </div>
                        {isFetching && <Spinner />}

                        <div className="buttons-lines">
                            <div onClick={() => this.setPasswordValue(1)}>1</div>
                            <div onClick={() => this.setPasswordValue(2)}>2</div>
                            <div onClick={() => this.setPasswordValue(3)}>3</div>
                        </div>
                        <div className="buttons-lines">
                            <div onClick={() => this.setPasswordValue(4)}>4</div>
                            <div onClick={() => this.setPasswordValue(5)}>5</div>
                            <div onClick={() => this.setPasswordValue(6)}>6</div>
                        </div>
                        <div className="buttons-lines">
                            <div onClick={() => this.setPasswordValue(7)}>7</div>
                            <div onClick={() => this.setPasswordValue(8)}>8</div>
                            <div onClick={() => this.setPasswordValue(9)}>9</div>
                        </div>
                        <div className="buttons-lines action-buttons">
                            <div className="back" onClick={() => this.setState({ password: passwordWithoutLastChr })}> &lt; </div>
                            <div onClick={() => this.setPasswordValue(0)}>0</div>
                            <div className="ok" onClick={() => !isFetching && this.checPasswordAndSubmit()}>

                                OK
                            </div>

                        </div>
                        <div className="error-container">{error}</div>
                    </div>
                </div>
            </div>
        )
    };
}