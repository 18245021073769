
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import { compose } from "redux";
import SideBar from './sidebar';
import { onShowMenu } from "../../store/action-creators/ActionCreators"

const mapStateToProps = (state) => ({
    showMenu: state.GlobalReducer.showMenu,
});

export default withRouter(compose(connect(mapStateToProps, {
    onShowMenu
}))(SideBar));