import ConfirmModal from './confirm-modal';
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import { compose } from "redux";
import { onSubmitFormStatus, onShowConfirmModal } from "../../store/action-creators/ActionCreators"

const mapStateToProps = (state) => ({
    showConfirmModal: state.GlobalReducer.showConfirmModal,
});

export default withRouter(compose(connect(mapStateToProps, {
    onSubmitFormStatus,
    onShowConfirmModal
}))(ConfirmModal));