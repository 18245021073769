import {
    SHOW_CONFIRM_MODAL,
    SHOW_MENU,
    SUBMIT_FORM_STATUS
} from "../actions";

export function onShowConfirmModal(formName) {
    return dispatch => {
        dispatch({ type: SHOW_CONFIRM_MODAL, payload: formName });
    }
}

export function onSubmitFormStatus() {
    return dispatch => {
        dispatch({ type: SUBMIT_FORM_STATUS });
    }
}

export function onShowMenu() {
    return dispatch => {
        dispatch({ type: SHOW_MENU });
    }
}