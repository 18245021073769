import moment from "moment"

export const API_URL = 'https://cash-admin.ssv-design.com/Main';

export const GET_PRODUCT_NAME_BY_ID = API_URL + '/getProductNameById';
export const GET_SERVICES = API_URL + '/getServices'; /** */
export const GET_PAYMENTS = API_URL + '/getPayments';/** */
export const ADD_SERVICE = API_URL + '/addService'; /** */
export const REMOVE_SERVICE = API_URL + '/removeService'; /* */
export const EDIT_TABLE_FIELD = API_URL + '/editTableField';
export const GET_PAYMENTS_LIST = API_URL + '/getPaymentsList';
export const ADD_PAYMENT = API_URL + '/addPayment';
export const GET_COUNT_ALL_ORDERS = API_URL + '/countAllOrders';
export const SHOW_PAYMENT = API_URL + '/showPayment';
export const EDIT_PAYMENT = API_URL + '/editPayment';

export const GLOBAL_PASSWORD = 2933

// export const amountFormatter = (number) => Number(number).toFixed(2);
export const amountFormatter = (number) => Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');
export const ITEMS_ON_THE_PAGE = 10

export const yearsList = () => {
    let years = [];

    const yearNow = moment().format("YYYY")

    for (let i = 2017; i <= yearNow; i++) {
        years.push({
            id: i,
            name: i
        })

    }

    return years
}

export const monthsList = () => {
    const monts = [
        { id: 1, name: "Январь" },
        { id: 2, name: "Февраль" },
        { id: 3, name: "Март" },
        { id: 4, name: "Апрель" },
        { id: 5, name: "Май" },
        { id: 6, name: "Июнь" },
        { id: 7, name: "Июль" },
        { id: 8, name: "Август" },
        { id: 9, name: "Сентябрь" },
        { id: 10, name: "Октябрь" },
        { id: 11, name: "Ноябрь" },
        { id: 12, name: "Декабрь" },
    ]

    return monts
}

export const regCash = (text) => {
    const regex = /,/i;
    const newCash = text && text.replace(regex, '.');

    return newCash
}


export const scrollToElement = (elem = "root") => {
    const element = document.getElementById(elem)
    const bodyRect = document.body.getBoundingClientRect()
    const elemRect = element.getBoundingClientRect()
    const needScrollToMyDivInPX = elemRect.top - bodyRect.top

    window.scrollTo({
        top: needScrollToMyDivInPX,
        behavior: 'smooth',
    })
}


export const ADD_PAYMENT_FORM = "ADD_PAYMENT_FORM"
export const ADD_SERVICE_FORM = "ADD_SERVICE_FORM"
export const EDIT_SERVICE_FORM = "EDIT_SERVICE_FORM"
export const DELETE_SERVICE_FORM = "DELETE_SERVICE_FORM"
export const SEARCH_PAYMENT_FORM = "SEARCH_PAYMENT_FORM"
export const EDIT_PAYMENT_FORM = "EDIT_PAYMENT_FORM"
