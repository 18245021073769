import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Statistic from '../statistic';
import Library from '../library';
import AddOrder from '../add-payment';
import EditPayment from '../edit-payment';
import classnames from 'classnames'
import ConfirmModal from "../../helpers/confirm-modal"
import menu from "../../images/menu.png"

import './app.scss'

export default class App extends Component {

    render() {
        const { showMenu, onShowMenu, showConfirmModal } = this.props
        console.log("showConfirmModal", showConfirmModal)
        return (
            <>
                <img
                    src={menu}
                    alt="Burger-menu"
                    className={classnames('burger-menu', showMenu && "hide-burder")}
                    onClick={() => onShowMenu()} />
                {showConfirmModal && <ConfirmModal />}
                <Router>
                    <Switch>
                        <Route path="/add-payment" exact render={() => (
                            <AddOrder pageTitle={'Новая оплата'} />
                        )} />

                        <Route path="/" exact render={() => (
                            <AddOrder pageTitle={'Новая оплата'} />
                        )} />

                        <Route path="/statistic" exact render={() => (
                            <Statistic pageTitle={'Статистика'} />
                        )} />

                        <Route path="/library" exact render={() => (
                            <Library pageTitle={'Услуги'} />
                        )} />

                        <Route path="/edit" exact render={() => (
                            <EditPayment pageTitle={'Редактирование'} />
                        )} />
                    </Switch>
                </Router>
            </>

        )
    }
}