import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import { compose } from "redux";
import AddPayment from './add-payment';
import { onShowConfirmModal } from "../../store/action-creators/ActionCreators"

const mapStateToProps = (state) => ({
    activeForm: state.GlobalReducer.activeForm,
    onSubmitForm: state.GlobalReducer.onSubmitForm,
});

export default withRouter(compose(connect(mapStateToProps, {
    onShowConfirmModal
}))(AddPayment));